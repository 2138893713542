import React from "react";
import { AnchorProps, rem } from "@mantine/core";
import { usePageContext } from "../renderer/usePageContext";

export { Link };

type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  to: string;
  style?: React.CSSProperties;
  children: string | React.ReactNode;
};

function Link({ to, style, children, ...props }: LinkProps) {
  const pageContext = usePageContext();
  const { urlPathname } = pageContext;
  const isActive = to === "/" ? urlPathname === to : urlPathname.startsWith(to);

  return (
    <a
      href={to}
      className="link"
      style={{
        display: "block",
        lineHeight: 1,
        padding: `${rem("8px")} ${rem("12px")}`,
        borderRadius: "var(--mantine-radius-sm)",
        textDecoration: "none",
        color: isActive
          ? `var(--mantine-color-primary-filled)`
          : `var(--mantine-color-gray-1)`,
        fontSize: `var(--mantine-font-size-sm)`,
        fontWeight: 500,
        ...style,
      }}
      onClick={(event) => event.preventDefault()}
      {...props}
    >
      {children}
    </a>
  );
}
