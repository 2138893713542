import a from "axios";
import { BACKEND_URL } from "../../constants/Settings";
import { navigate } from "vike/client/router";
import { getDefaultStore } from "jotai";
import { authenticatedUserAtom } from "../../hooks/authenticatedUserAtom";
import { isAuthenticatedAtom } from "../../hooks/isAuthenticatedAtom";
const store = getDefaultStore();

const axios = a.create({
  baseURL: `${BACKEND_URL}`,
  withCredentials: true,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (error?.response?.status === 401) {
      store.set(isAuthenticatedAtom, false);
      store.set(authenticatedUserAtom, {});
      navigate("/app/signin");
    }

    return Promise.reject(error);
  }
);

export default axios;
