import React from "react";
export { Logo };

function Logo({
  width = 120,
  height = 46,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 337 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.9025 0.967999H11.7825V45H0.9025V0.967999ZM39.945 45L30.345 18.312V45H19.465V0.967999H34.441L46.025 34.632L57.609 0.967999H72.585V45H61.705V18.312L52.105 45H39.945ZM103.062 35.528C104.15 35.528 105.174 35.464 106.198 35.336V28.424H96.6615V19.592H117.078V42.312C113.622 44.232 109.718 45.768 103.19 45.768C88.8535 45.768 78.3575 36.424 78.3575 23.048C78.3575 9.672 88.8535 0.327999 102.55 0.327999C109.078 0.327999 113.622 1.864 117.078 3.784V14.92C114.069 12.744 109.333 10.568 103.126 10.568C95.2535 10.568 89.8775 15.368 89.8775 23.048C89.8775 30.728 95.1895 35.528 103.062 35.528ZM146.562 35.528C147.65 35.528 148.674 35.464 149.698 35.336V28.424H140.162V19.592H160.578V42.312C157.122 44.232 153.218 45.768 146.69 45.768C132.354 45.768 121.858 36.424 121.858 23.048C121.858 9.672 132.354 0.327999 146.05 0.327999C152.578 0.327999 157.122 1.864 160.578 3.784V14.92C157.569 12.744 152.833 10.568 146.626 10.568C138.753 10.568 133.378 15.368 133.378 23.048C133.378 30.728 138.69 35.528 146.562 35.528ZM167.278 0.967999H202.222V11.208H178.158V17.736H199.022V27.976H178.158V34.76H202.222V45H167.278V0.967999ZM218.518 0.967999L236.246 26.824V0.967999H247.126V45H236.822L218.518 19.784V45H207.638V0.967999H218.518Z"
        fill="white"
      />
      <circle cx="260" cy="38" r="7" fill="#8BE300" />
      <path
        d="M283.743 45H271.839L289.375 0.967999H304.031L321.567 45H309.471L305.823 35.272H287.455L283.743 45ZM296.671 11.144L291.103 25.672H302.175L296.671 11.144ZM325.246 0.967999H336.126V45H325.246V0.967999Z"
        fill="white"
      />
    </svg>
  );
}
